// src/services/movieService.js
import api from '../api';

const getMovies = async () => {
  try {
    const response = await api.get('/movies');
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the movies!', error);
    throw error;
  }
};

const getMovie = async(id) => {
  try {
    const response = await api.get(`/movies/${id}`);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the movie!', error);
    throw error;
  }
}

export { getMovies, getMovie };
