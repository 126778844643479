// src/component/MoviesList.js
import { Grid, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMovies } from '../service/movieService';
import MovieCategories from './MovieCategories'

const MoviesList = () => {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const moviesData = await getMovies();
        setMovies(moviesData);
      } catch (error) {
        console.error('Failed to fetch movies:', error);
      }
    };

    fetchMovies();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        {movies.map((movie) => (
          
          <Grid item key={movie._id} xs={12} sm={6} md={3}>
            <Card>
              <CardActionArea component={Link} to={`/movies/${movie._id}`}>
              <CardMedia
                component="img"
                height="500"
                image={movie.poster_url}
                alt={movie.name}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {movie.name}
                </Typography>
                <MovieCategories movie={movie}/>
              </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MoviesList;
