// src/component/MovieCategories.js

import { Chip } from '@mui/material'

const MovieCategories = ({ movie }) => {
  const concatCategories = (movie.tmdb_categories ?? [])
    .concat(movie.distributor_categories ?? [])
    .map((category) => category.toLowerCase());

  console.log(concatCategories);
  const set = new Set(concatCategories);

  console.log(set);
  const categories = Array.from(set);

  return (
    <div style={{ marginTop: '10px' }}>
      {categories.map((category, index) => (
        <Chip key={index} label={category} color="primary" style={{ margin: '2px' }} />
      ))}
    </div>
  );
};

export default MovieCategories;