// src/component/MovieDetail.js
import { Container, Card, CardMedia, CardContent, Typography, Button, ImageList, ImageListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMovie } from '../service/movieService';
import MovieCategories from './MovieCategories';

const MovieDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [movie, setMovie] = useState([]);

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const movieData = await getMovie(id);
        setMovie(movieData);
      } catch (error) {
        console.error('Failed to fetch movie:', error);
      }
    };

    fetchMovie();
  }, [id]);

  if (!movie) {
    return <Typography variant="h6">Movie not found</Typography>;
  }

return (
    <Container>
        <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate(-1)} 
            style={{ marginTop: '20px' }}
        >
            Back
        </Button>
        <Card style={{ marginTop: '20px' }}>
            <CardMedia
                component="img"
                height="500"
                image={movie.backdrop_url}
                alt={movie.title}
            />
            <CardContent>
        <Typography variant="h4">{movie.name}</Typography>
        <Typography variant="h6">{movie.original_title}</Typography>
        <MovieCategories movie={movie} />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
            {movie.description}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
            <b>Rok výroby:</b> {movie.year}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
            <b>Dĺžka:</b> {Math.floor(movie.runtime / 60)} hodín {movie.runtime % 60} minút
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
            <b>Krajiny pôvodu:</b> {movie.production_countries}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
            <b>Dátum premiéry:</b> {new Date(movie.premiere_date).toLocaleDateString('sk-SK', { day: '2-digit', month: '2-digit', year: 'numeric' })}
        </Typography>
        {movie.actors && movie.actors.length > 0 && (
          <>
          <Typography variant="body1" style={{ marginTop: '20px' }}>
            <b>Obsadenie:</b> {movie.actors.join(", ")}
          </Typography>
          </>
          )
        }
        {movie.trailer_url && (
        <>
        <Typography variant="h5" style={{ marginTop: '20px' }}>
            Trailer:
        </Typography>
        
        <div style={{ marginTop: '10px' }}>
            <iframe
                width="100%"
                height="300"
                src={movie.trailer_url}
                title="Trailer"
                allow="autoplay; encrypted-media"
                allowFullScreen
            ></iframe>
        </div>
        </>)}
          

        {movie.images && (
          <>
          <ImageList sx={{ width: '100%', height: 'auto' }} cols={3} rowHeight={300}>
          {movie.images.map((image) => (
            <ImageListItem key={image}>
          <img
            src={image}
          alt={movie.title}
          loading="lazy"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
    </ImageListItem>
  ))}
</ImageList>
          
          </>
        )}


          </CardContent>
        </Card>

        


    </Container>
);
};

export default MovieDetail;